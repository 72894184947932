<template>
  <div class="modal fade " id="serviceShowWebEditModal" tabindex="-1" role="dialog" aria-labelledby="serviceShowWebEditModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Show Website Status</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12 ">
                <div class="form-group">
                  <div class="controls">
                    <label>Show Website</label>
                    <VueMultiselect :allowEmpty="false" v-model="selectedWebStatus" class="multiselect-blue" :options="allStatus" :close-on-select="true" placeholder="Select show web status" label="name" track-by="value" :show-labels="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateShowWebStatusHandler">
            <span class="">Save Changes</span>
          </button>
          <!-- data-dismiss="modal" -->
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import {
  
    mapActions,
    mapGetters
  } from 'vuex';
  import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
  import Loader from "@/components/backEnd/mixins/Loader";
  import VueMultiselect from 'vue-multiselect';
  export default {
    mixins: [ShowToastMessage, Loader],
    name: 'ServiceShowHomeEditModal',
    props: ['show_website'],
    components: {
      VueMultiselect
    },
    data() {
      return {
        selectedWebStatus: '',
        allStatus: [{
            value: 0,
            name: 'Inactive'
          },
          {
            value: 1,
            name: 'Active'
          },
        ],
  
      }
    },
    computed: {
      ...mapGetters({
        service: 'appService/service',
      }),
    },
    watch: {
      service(currentService) {
           
              this.selectedWebStatus = this.allStatus.find((status) => status.name == currentService.show_website);
          },
  
      },
      methods: {
          ...mapActions({
              putService: 'appService/putService',
  
          }),
          async updateShowWebStatusHandler() {
              let data = {
                  show_website: this.selectedWebStatus.value,
              }
              const dataObj = {
                  id: this.$route.params.id,
                  data: data
              }
              this.loader(true);
              const response = await this.putService(dataObj);
              this.loader(false);
              if (response.status === 200 || response.status === 201) {
                  this.showToastMessage(response.message);
                  document.querySelector('[data-target="#serviceShowWebEditModal"]').click();
                  this.$emit('getSingleService', this.$route.params.id);
                 
              } else {
                  this.errors = response.errors;
              }
              if (response.message) {
                  this.showToastMessage(response);
              }
          }
      },
  }
  </script>
  
  <style scoped>
  
  </style>
  