<template>
<div class="modal fade " id="ServiceDescriptionEditModal" tabindex="-1" role="dialog" aria-labelledby="ServiceDescriptionEditModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Service Description</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Description</label>
                                <QuillEditor ref="serviceDescriptionEditor" v-model:content="serviceDescription" contentType="html" toolbar="full" theme="snow" />

                                <!-- <div class="text-danger" v-if="errors.technician.skills">{{ errors.technician.skills }}</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateServiceDescriptionHandler">
                    <span class="">Save Changes</span>
                </button>
                <!-- data-dismiss="modal" -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions,
    mapGetters
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
    QuillEditor
} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
    mixins: [ShowToastMessage, Loader],
    name: "ServiceDescriptionEditModal",

    components: {
        QuillEditor
    },
    data() {
        return {

            serviceDescription: '',

        }
    },
    computed: {
        ...mapGetters({
            service: 'appService/service',
        }),
    },
    watch: {
        service(currentService) {
            this.serviceDescription = currentService.description;
            this.$refs.serviceDescriptionEditor.setHTML(this.serviceDescription);
        },

    },
    methods: {
        ...mapActions({
            putService: 'appService/putService',

        }),
        async updateServiceDescriptionHandler() {
            let data = {
                description: this.serviceDescription,
            }
            const dataObj = {
                id: this.$route.params.id,
                data: data
            }
            this.loader(true);
            const response = await this.putService(dataObj);
            this.loader(false);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#ServiceDescriptionEditModal"]').click();
                this.errors = {};
                this.showToastMessage(response.message);
                this.$emit('getSingleService', this.$route.params.id);
            } else {
                this.errors = response.errors;
            }
            if (response.message) {
                this.showToastMessage(response);
            }
        }
    },

}
</script>

<style>

</style>
