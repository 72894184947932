<template>
  <div class="modal fade " id="metaInfoEditModal" tabindex="-1" role="dialog" aria-labelledby="metaInfoEditModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Update Meta Information</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <h5 class="my-1 text-uppercase">Meta Info</h5>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="">
                    <label>Author Name</label>
                    <input v-model="putServiceData.meta_author" type="text" class="form-control"
                           placeholder="Enter Product Name">
                  </div>
                </div>

              </div>
              <div class="col-12 ">
                <div class="form-group">
                  <div class="">
                    <label>Description</label>
                    <textarea class="form-control" rows="5" v-model="putServiceData.meta_description"></textarea>
                  </div>
                </div>

              </div>
              <div class="col-12 ">
                <div class="form-group">
                  <div class="">
                    <label>Meta Keywords</label>
                    <textarea class="form-control" rows="5" v-model="putServiceData.meta_keywords"></textarea>
                  </div>
                </div>

              </div>
              <div class="col-12 ">
                <div class="form-group">
                  <div class="">
                    <label>Other Meta Data</label>
                    <textarea class="form-control" rows="5" v-model="putServiceData.other_meta_data"></textarea>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="updateServiceMetaHandler">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
          <button data-dismiss="modal" style="visibility: hidden"></button>

          <!-- data-dismiss="modal" -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>


import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  mixins: [ShowToastMessage, Loader],
  name: "MetaInfoUpdateModal",
  components: {},
  props: ['basicInfoObj'],
  emits: ['getSingleService'],
  data() {
    return {
      putServiceData: {
        meta_author: '',
        meta_description: '',
        meta_keywords: '',
        other_meta_data: '',
      },


    }
  },
  computed: {
    ...mapGetters({
      service: 'appService/service',
    }),
  },
  watch: {
    service(value) {
      this.putServiceData = {
        meta_author: value?.meta_author ?? '',
        meta_description: value?.meta_description ?? '',
        meta_keywords: value?.meta_keywords ?? '',
        other_meta_data: value?.other_meta_data ?? '',

      };
    },

  },

  methods: {
    ...mapActions({
      putService: 'appService/putService',

    }),
    async updateServiceMetaHandler() {
      const dataObj = {
        id: this.$route.params.id,
        data: {...this.putServiceData}
      }
      this.loader(true);
      const response = await this.putService(dataObj);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        document.querySelector('[data-target="#metaInfoEditModal"]').click();
        this.showToastMessage(response.message);
        this.$emit('getSingleService', this.$route.params.id);
      } else {
        this.errors = response.errors;
      }
      if (response.message) {
        this.showToastMessage(response);
      }
    }
  },

}
</script>

<style scoped>

</style>