<template>
<div class="modal fade " id="serviceCategoryUpdateModal" tabindex="-1" role="dialog" aria-labelledby="serviceCategoryUpdateModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Service Category</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-12 ">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Service Category</label>
                                    <VueMultiselect :allowEmpty="false" v-model="selectedServiceCategory" class="multiselect-blue" :options="serviceCategories" :close-on-select="true" placeholder="Select " label="name" track-by="id" :show-labels="false" />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateServiceCategory">
                    <span class="">Save Changes</span>
                </button>
                <!-- data-dismiss="modal" -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions,
    mapGetters
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import VueMultiselect from 'vue-multiselect';
export default {
    mixins: [ShowToastMessage, Loader],
    name: "ServiceCategoryUpdateModal",
    props: ['status'],
    components: {
        VueMultiselect
    },
    data() {
        return {
            selectedServiceCategory: '',
        }
    },
    computed: {
        ...mapGetters({
            service: 'appService/service',
            serviceCategories: "appServiceCategories/serviceCategories",
        }),
    },
    watch: {
        service(currentService) {
            const selectedPreviousServiceCategory = this.serviceCategories.find((serviceCategory) => serviceCategory.id == currentService ?.serviceCategory ?.id);
            if (selectedPreviousServiceCategory !== undefined) {
                this.selectedServiceCategory = selectedPreviousServiceCategory;
            }
        },

    },
    methods: {
        ...mapActions({
            putService: 'appService/putService',

        }),
        async updateServiceCategory() {
            if (this.selectedServiceCategory) {
                let data = {
                    service_category_id: this.selectedServiceCategory.id,
                }
                const dataObj = {
                    id: this.$route.params.id,
                    data: data
                }
                this.loader(true);
                const response = await this.putService(dataObj);
                this.loader(false);
                if (response.status === 200 || response.status === 201) {
                    this.showToastMessage(response.message);
                    document.querySelector('[data-target="#serviceCategoryUpdateModal"]').click();
                    this.$emit('getSingleService', this.$route.params.id);

                } else {
                    this.errors = response.errors;
                }
                if (response.message) {
                    this.showToastMessage(response);
                }
            }
            else {
                this.showToastMessage({
                    type:'error',
                    message:'Please select a service category'
                });
            }

        }
    },

}
</script>

<style>

</style>
