<template>
<AppLayout>
  <template v-slot:appContent>
    <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
      <h4 class="">Services/View</h4>
      <div>
        <router-link :to="{...previousRoute}">
          <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
        </router-link>
      </div>
    </div>

    <div ref="singleServiceViewRef">
      <div class="content-body">
        <section class="page-user-profile">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="d-block d-sm-flex  align-items-center">
                    <div class="mr-2 position-relative">
                      <img :src="service.image" alt="users avatar" class="users-avatar-shadow rounded-circle" :style="{border:'1px solid gray'}" height="120" width="120">
                      <div class="position-absolute update-image-avatar d-flex align-items-center justify-content-center" @click="openImageUpdateModal">
                        <i class='bx bxs-camera'></i>
                      </div>
                    </div>
                    <div class="media-body ">

                      <h4 class="media-heading text-capitalize">{{ service.name }}
                        <span class="bullet bullet-sm" :class="service.status === 'Active' ? 'bullet-success' : 'bullet-danger'"></span>
                      </h4>

                      <p><span class="text-muted text-capitalize">{{ service.serviceCategory && service.serviceCategory.name
                            ? service.serviceCategory.name
                            : 'No Service Category Assigned' }}
                        </span> <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#serviceCategoryUpdateModal" class="btn pl-0 text-primary">
                          <i class='bx bx-edit-alt'></i>
                        </button></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 ">
                  <div class="card ">
                    <div class="card-body">
                      <table id="users-list-datatable" class="table">
                        <thead>
                          <tr>
                            <th class="position-relative" :style="{width:'20%'}">STATE

                            </th>
                            <th class="position-relative" :style="{width:'15%'}">HOME PRICE

                            </th>
                            <th class="position-relative" :style="{width:'15%'}">BUSINESS PRICE

                            </th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(statePrice, index) in statePriceList" :key="index">
                            <td>{{ statePrice.state }}</td>
                            <td>{{ statePrice.home_price }}</td>
                            <td>{{ statePrice.business_price }}</td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                  <div class="card ">
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-1">
                        <div>
                          <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">Meta
                            Information</h5>
                        </div>
                        <div class="ml-1" style="cursor: pointer;"  v-if="can('product-update')">
                            <span data-toggle="modal" data-target="#metaInfoEditModal" title="Edit"
                                  class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span>
                        </div>
                      </div>
                      <ul v-if="service.name !== undefined" class="list-unstyled">
                        <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Meta Author: </span>
                          <span>{{ service?.meta_author ?? 'Not provided' }}</span>
                        </li>
                        <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Meta Description: </span>
                          <span>{{  service?.meta_description ?? 'Not provided' }}</span>
                        </li>
                        <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize"
                                  style="text-transform: capitalize;">Meta Keywords: </span>
                          <span>{{  service?.meta_keywords ?? 'Not provided' }}</span>
                        </li>
                        <li class="mb-1">
                          <span class="text-bold-700 mb-1">Other Meta Data: </span>
                          <span>{{  service?.other_meta_data ?? 'Not provided' }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 mb-4">
                  <div class="tab-content  ">
                    <div aria-labelledby="profile-tab">
                      <div class="card">
                        <div class="card-body">
                          <ul class="list-unstyled">
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">Name : </span>
                                  <span class="text-capitalize">{{ service.name }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#serviceNameEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>
                            </li>

                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 mb-1">Home Price : </span>
                                  <span>{{ parseFloat(service.home_price /100).toFixed(2) }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#serviceHomePriceEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>

                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 mb-1">Business Price : </span>
                                  <span>{{ parseFloat(service.business_price /100).toFixed(2) }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#serviceBusinessPriceEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>

                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 ">Code : </span>
                                  <span>{{ service.code }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#serviceCodeEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>

                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 ">Source : </span>
                                  <span>{{ service.source }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#serviceSourceEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>

                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 ">Commission : </span>
                                  <span>{{ service.commission }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#serviceCommissionEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>

                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 mb-1">Status : </span>
                                  <span>{{ service.status }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Name Forbidden'" data-toggle="modal" data-target="#ServiceStatusEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>
                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 mb-1">Show Home : </span>
                                  <span>{{ service.show_home }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Name Forbidden'" data-toggle="modal" data-target="#serviceShowHomeEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>
                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 mb-1">Show Website : </span>
                                  <span>{{ service.show_website }}</span>
                                </div>
                                <div class="ml-1">
                                  <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Name Forbidden'" data-toggle="modal" data-target="#serviceShowWebEditModal" class="btn single-edit-btn text-primary">
                                    <i class='bx bx-edit-alt'></i>
                                  </button>
                                </div>
                              </div>

                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 mb-1">Show Online Appointment : </span>
                                  <span>{{  service.show_online_appointment }}</span>
                                </div>
                                    <div class="ml-1">
                                      <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update' : 'Update Name Forbidden'" data-toggle="modal" data-target="#serviceShowOnlineAppointmentEditModal" class="btn single-edit-btn text-primary">
                                        <i class='bx bx-edit-alt'></i>
                                      </button>
                                    </div>
                              </div>
                            </li>
                            <li class="mb-1">
                              <div class="d-flex align-items-center">
                                <div>
                                  <span class="text-bold-700 mb-1">Description : <button :disabled="!can('service-update')" :title="can('service-update') ? 'Update Name' : 'Update Name Forbidden'" data-toggle="modal" data-target="#ServiceDescriptionEditModal" class="btn single-edit-btn text-primary">
                                      <i class='bx bx-edit-alt'></i>
                                    </button></span>
                                  <span class="mb-0" v-html="service.description"></span>
                                </div>
                                <div class="ml-1">

                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ServiceNameEditModalVue v-on:getSingleService="getSingleService($event)" />
      <ServiceCommissionEditModal v-on:getSingleService="getSingleService($event)" />
      <ServiceStatusEditModal v-on:getSingleService="getSingleService($event)" />
      <ServiceHomePriceEditModal v-on:getSingleService="getSingleService($event)" />
      <ServiceBusinessPriceEditModal v-on:getSingleService="getSingleService($event)" />
      <ServiceDescriptionEditModal v-on:getSingleService="getSingleService($event)" />
      <ServiceCategoryUpdateModal v-on:getSingleService="getSingleService($event)" />
      <ServiceShowHomeEditModal v-on:getSingleService="getSingleService($event)" />
      <ServiceShowWebEditModal v-on:getSingleService="getSingleService($event)" />
      <ServiceShowOnlineAppointmentEditModal v-on:getSingleService="getSingleService($event)" />
      <MetaInfoUpdateModal v-on:getSingleService="getSingleService($event)" />
      <ServiceCodeEditModal v-on:getSingleService="getSingleService($event)" />
      <ServiceSourceEditModal v-on:getSingleService="getSingleService($event)" />


      <!-- for modal open dynamically -->
      <div class="" data-toggle="modal" data-target="#updateAvatarModal"></div>
      <UpdateImageModal v-on:getSingleView="uploadImage($event)" :previousImage="service?.image ?? defaultImage" />
    </div>
  </template>
</AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import ServiceNameEditModalVue from '@/views/backEnd/services/includes/ServiceNameEditModal.vue';
import ServiceCommissionEditModal from '@/views/backEnd/services/includes/ServiceCommissionEditModal.vue';
import ServiceStatusEditModal from '@/views/backEnd/services/includes/ServiceStatusEditModal.vue';
import ServiceDescriptionEditModal from '@/views/backEnd/services/includes/ServiceDescriptionEditModal.vue';
import ServiceHomePriceEditModal from '@/views/backEnd/services/includes/ServiceHomePriceEditModal.vue';
import ServiceBusinessPriceEditModal from '@/views/backEnd/services/includes/ServiceBusinessPriceEditModal.vue';
import ServiceCategoryUpdateModal from '@/views/backEnd/services/includes/ServiceCategoryUpdateModal.vue';
import UpdateImageModal from '@/views/backEnd/services/includes/ServiceImageUpdateModal.vue';
import ServiceShowHomeEditModal from '@/views/backEnd/services/includes/ServiceShowHomeEditModal.vue';
import ServiceShowOnlineAppointmentEditModal from '@/views/backEnd/services/includes/ServiceShowOnlineAppointmentEditModal.vue';
import ServiceShowWebEditModal from '@/views/backEnd/services/includes/ServiceShowWebEditModal.vue';
// mixins
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
// core packages
import {
  mapActions,
  mapGetters
} from 'vuex'
import MetaInfoUpdateModal from "@/views/backEnd/services/includes/MetaInfoUpdateModal.vue";
import ServiceCodeEditModal from "@/views/backEnd/services/includes/ServiceCodeEditModal.vue";
import ServiceSourceEditModal from "@/views/backEnd/services/includes/ServiceSourceEditModal.vue";


export default {
  components: {
    ServiceSourceEditModal,
    ServiceCodeEditModal,
    MetaInfoUpdateModal,
    AppLayout,
    ServiceNameEditModalVue,
    ServiceCommissionEditModal,
    ServiceStatusEditModal,
    ServiceDescriptionEditModal,
    UpdateImageModal,
    ServiceHomePriceEditModal,
    ServiceBusinessPriceEditModal,
    ServiceCategoryUpdateModal,
    ServiceShowHomeEditModal,
    ServiceShowOnlineAppointmentEditModal,
    ServiceShowWebEditModal
},
  mixins: [ShowToastMessage, Loader, Authorization],
  name: "ServiceView",
  data() {
    return {
      defaultImage: `${process.env.VUE_APP_API_URL}default/images/services/no-image.png`,
      getSettingsParams: {
        type: ['service'],
        key: ['service_state_price_rate']
      },
      getServiceParams: {
        with_relation: ['addedBy', 'serviceCategory', 'media'],
      },
      homePrice: '',
      businessPrice: '',
      code: '',
      commission: '',
      description: '',
      id: '',
      image: '',
      name: '',
      status: '',
      showHome:'',
      showOnlineAppointment:'',
      serviceCategoryName: ''
    }
  },
  computed: {
    ...mapGetters({
      settingServiceStatePriceRate: 'appSettings/settingServiceStatePriceRate',
      service: 'appService/service',
      previousRoute: 'previousRoute',
    }),

    statePriceList() {
      return this.settingServiceStatePriceRate.value ? this.settingServiceStatePriceRate.value.map(item => {
        return {
          state: item.state.toUpperCase(),
          home_price: this.service.home_price ? (this.service.home_price * item.rate / 100).toFixed(2) : 0,
          business_price: this.service.business_price ? (this.service.business_price * item.rate / 100).toFixed(2) : 0,
        }
      }) : [];
    },
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getService: 'appService/getService',
      putImage: 'appService/putImage',
      commitNotFoundRouteStatus: 'commitNotFoundRouteStatus',
      getServiceCategories: "appServiceCategories/getServiceCategories",
      getReferral: "appReferrals/getReferrals",
    }),
    async getServiceCategoryList() {
      await this.getServiceCategories({
        status: 1,
        order_by_name: 'ASC',
      });
    },
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
    async getSingleService() {
      const paramObj = {
        id: this.$route.params.id,
        params: this.getServiceParams,
      };
      this.loader(true);
      await this.getService(paramObj).then((response) => {
        this.loader(false);
        if (response.status === 404) {
          this.commitNotFoundRouteStatus(true);
          return;
        }
        if (response && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
    openImageUpdateModal() {
      document.querySelector('[data-target="#updateAvatarModal"]').click();
    },

    async uploadImage(imageBase64) {
      this.image = imageBase64;
      const payLoad = {
        id: this.service.id,
        data: {
          image: imageBase64
        }
      }
      const response = await this.putImage(payLoad);
      if (response.status === 201 || response.status === 200) {
        this.loader(false);
        document.querySelector('[data-target="#updateAvatarModal"]').click();
        await this.getSingleService(this.$route.params.id)
        // this.$emit('getSingleView', this.$route.params.id);

      }
      if (response && response.message) {
        this.showToastMessage(response);
      }
    }
  },

  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getServiceCategoryList();
    await this.getReferral();
    await this.getSingleService(this.$route.params.id);
    await this.loader(false);

  },
}
</script>

<style scoped>
p {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 600;
}

.update-image-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #5a8dee;
  border: 2px solid white;
  top: 79%;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.update-image-avatar:hover {
  background-color: #4173ce;
}

.update-image-avatar>i {
  font-size: 20px;
  z-index: 10;
  color: white;
}

@media only screen and (max-width: 576px) {
  .update-image-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #5a8dee;
    border: 2px solid white;
    top: 64%;
    left: 92px;
    right: 0;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}

.single-edit-btn {
  cursor: pointer;
}
</style>
