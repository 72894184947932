<template>
<div class="modal fade " id="serviceHomePriceEditModal" tabindex="-1" role="dialog" aria-labelledby="serviceHomePriceEditModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Service Home Price</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="">
                                    <label>Service Home Price </label>
                                    <input v-model="serviceHomePrice" type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateServiceHomePriceHandler">
                    <span class="">Save Changes</span>
                </button>
                <!-- data-dismiss="modal" -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions,
    mapGetters
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
export default {
    mixins: [ShowToastMessage, Loader],
    name: "ServiceHomePriceEditModal",
    data() {
        return {
            serviceHomePrice: '',
        }
    },
    computed: {
        ...mapGetters({
            service: 'appService/service',
        }),
    },
    watch: {
        service(currentService) {
            this.serviceHomePrice = parseFloat(currentService.home_price / 100).toFixed(2);
        },

    },
    methods: {
        ...mapActions({
            putService: 'appService/putService',

        }),
        async updateServiceHomePriceHandler() {
            let data = {
                home_price: parseInt(this.serviceHomePrice) * 100,
            }
            const dataObj = {
                id: this.$route.params.id,
                data: data
            }
            this.loader(true);
            const response = await this.putService(dataObj);
            this.loader(false);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#serviceHomePriceEditModal"]').click();
                this.errors = {};
                this.showToastMessage(response.message);
                this.$emit('getSingleService', this.$route.params.id);
            } else {
                this.errors = response.errors;
            }
            if (response.message) {
                this.showToastMessage(response);
            }
        }
    },

}
</script>

<style>

</style>
